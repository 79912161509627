import Vue from 'vue'
import { HTTP_GET, HTTP_POST, HTTP_PUT } from '../const';
import {
  RESET_SETTINGS,
  LOAD_SETTINGS,
  UPDATE_SETTING,
  LOAD_USER_BALANCE, LOAD_EXCHANGE_INFO,
} from "../const/settings";

export default {
  namespaced: true,
  state: {
    settings: {
      work_status: false,
    },
    userBalance: {
      balance: 0,
      balance_dep: 0,
      updated: '',
    },
    paymentSystems: [
      { alias: 'card', label: 'Карты' },
      { alias: 'sbp', label: 'СБП' },
      { alias: 'sim', label: 'СИМ' },
    ],
    banks: [
      {
        "id": 1,
        "label": "Сбербанк",
        "alias": "sber"
      },
      {
        "id": 2,
        "label": "Тинькофф Банк",
        "alias": "tinkoff"
      },
      {
        "id": 3,
        "label": "Райффайзенбанк",
        "alias": "raiffeisen"
      },
      {
        "id": 4,
        "label": "ВТБ",
        "alias": "vtb"
      },
      {
        "id": 5,
        "label": "Альфа-Банк",
        "alias": "alfa"
      },
      {
        "id": 6,
        "label": "Газпромбанк",
        "alias": "gazprom"
      },
      {
        "id": 7,
        "label": "Банк Открытие",
        "alias": "otkrytie"
      },
      {
        "id": 8,
        "label": "Россельхозбанк",
        "alias": "rosselhoz"
      },
      {
        "id": 9,
        "label": "Совкомбанк",
        "alias": "sovkom"
      },
      {
        "id": 10,
        "label": "Росбанк",
        "alias": "rosbank"
      },
      {
        "id": 11,
        "label": "МТС банк",
        "alias": "mts"
      },
      {
        "id": 12,
        "label": "Банк Санкт-Петербург",
        "alias": "spb"
      },
      {
        "id": 13,
        "label": "Озон банк",
        "alias": "ozon"
      },
      {
        "id": 14,
        "label": "Уралсиб банк",
        "alias": "uralsib"
      },
      {
        "id": 15,
        "label": "МКБ банк",
        "alias": "mkb"
      },
      {
        "id": 16,
        "label": "Промсвязьбанк",
        "alias": "prom"
      },
      {
        "id": 17,
        "label": "Банк «УБРиР»",
        "alias": "ubrr"
      },
      {
        "id": 18,
        "label": "Хоум Банк",
        "alias": "home"
      },
      {
        "id": 19,
        "label": "ОТП-Банк",
        "alias": "otp"
      },
      {
        "id": 20,
        "label": "Ак Барс Банк",
        "alias": "akbars"
      },
      {
        "id": 21,
        "label": "Фора-Банк",
        "alias": "fora"
      },
      {
        "id": 22,
        "label": "Абсолют Банк",
        "alias": "absolute"
      },
      {
        "id": 23,
        "label": "РостФинанс Банк",
        "alias": "rostfinance"
      },
      {
        "id": 24,
        "label": "Юнистрим",
        "alias": "unistream"
      },
      {
        "id": 25,
        "label": "Транскапиталбанк",
        "alias": "transcapital"
      },
      {
        "id": 26,
        "label": "Почта Банк",
        "alias": "pochta"
      },
      {
        "id": 27,
        "label": "РНКБ Банк",
        "alias": "rncb"
      },
      {
        "id": 28,
        "label": "Генбанк",
        "alias": "genbank"
      },
      {
        "id": 29,
        "label": "Банк Русский Стандарт",
        "alias": "russtandart"
      },
      {
        "id": 30,
        "label": "ЭкспоБанк",
        "alias": "expobank"
      },
      {
        "id": 31,
        "label": "Банк Авангард",
        "alias": "avangard"
      },
      {
        "id": 32,
        "label": "ИнтерПрогрессБанк",
        "alias": "interprogress"
      },
      {
        "id": 33,
        "label": "Банк Дом.Рф",
        "alias": "domrf"
      },
      {
        "id": 34,
        "label": "Яндекс банк",
        "alias": "yandex"
      },
      {
        "id": 35,
        "label": "РЕСО Кредит",
        "alias": "resocredit"
      },
      {
        "id": 36,
        "label": "Кредит Европа Банк",
        "alias": "crediteurope"
      },
      {
        "id": 37,
        "label": "БКС Банк",
        "alias": "bksbank"
      },
      {
        "id": 38,
        "label": "Ренессанс",
        "alias": "renessans"
      },
      {
        "id": 39,
        "label": "ЮниКредит",
        "alias": "unicredit"
      },
      {
        "id": 40,
        "label": "СИТИбанк",
        "alias": "citybank"
      },
      {
        "id": 41,
        "label": "ВБРР",
        "alias": "vbrr"
      },
      {
        "id": 42,
        "label": "Новиком",
        "alias": "novicom"
      },
      {
        "id": 43,
        "label": "Банк Зенит",
        "alias": "zenit"
      },
      {
        "id": 44,
        "label": "Цифра Банк",
        "alias": "tsyfra"
      },
      {
        "id": 45,
        "label": "СургутНефтегаз",
        "alias": "surgutneftegaz"
      },
      {
        "id": 46,
        "label": "Морской банк",
        "alias": "morskoy"
      },
      {
        "id": 47,
        "label": "Металлинвестбанк",
        "alias": "metalinvestbank"
      },
      {
        "id": 49,
        "label": "Ингосстрах Банк",
        "alias": "ingosstrah"
      },
      {
        "id": 50,
        "label": "АБ Россия",
        "alias": "abrussia"
      },
      {
        "id": 51,
        "label": "Свой Банк",
        "alias": "svoibank"
      },
      {
        "id": 52,
        "label": "Банк Солидарность",
        "alias": "solidarnost"
      },
      {
        "id": 53,
        "label": "KapitalBank (КапиталБанк)",
        "alias": "kapitalbank"
      },
      {
        "id": 54,
        "label": "Ipak Yuli Bank",
        "alias": "ipakyulibank"
      },
      {
        "id": 55,
        "label": "Национальный банк Узбекистана (НБУ)",
        "alias": "nationalbankuzbekistan"
      },
      {
        "id": 56,
        "label": "Алиф банк",
        "alias": "alifbank"
      },
      {
        "id": 57,
        "label": "Душанбе сити",
        "alias": "dushanbecity"
      },
      {
        "id": 58,
        "label": "Амра Банк",
        "alias": "amrabank"
      },
      {
        "id": 59,
        "label": "ЮMoney",
        "alias": "umoney"
      },
      {
        "id": 60,
        "label": "Челябинвестбанк",
        "alias": "chelyabinvestbank"
      },
      {
        "id": 61,
        "label": "Живаго Банк",
        "alias": "zhivagobank"
      },
      {
        "id": 62,
        "label": "ББР банк",
        "alias": "bbrbank"
      },
      {
        "id": 63,
        "label": "Синара банк",
        "alias": "sinarabank"
      },
      {
        "id": 64,
        "label": "Универсал банк Абхазия",
        "alias": "universalbankabhaziya"
      },
      {
        "id": 65,
        "label": "ПримСоцБанк",
        "alias": "primsotsbank"
      },
      {
        "id": 66,
        "label": "Углемет банк",
        "alias": "uglemetbank"
      },
      {
        "id": 67,
        "label": "Спитамен Банк",
        "alias": "spitamenbank"
      },
      {
        "id": 68,
        "label": "М10 банк/E-wallet",
        "alias": "m10bank"
      },
      {
        "id": 69,
        "label": "Leobank",
        "alias": "leobank"
      },
      {
        "id": 70,
        "label": "ABB bank",
        "alias": "abbbank"
      },
      {
        "id": 71,
        "label": "Юнибанк",
        "alias": "yunibank"
      },
      {
        "id": 72,
        "label": "PASHA Bank",
        "alias": "pashabank"
      },
      {
        "id": 73,
        "label": "МТС Деньги (ЭКСИ-Банк)",
        "alias": "mtsdengi"
      },
      {
        "id": 74,
        "label": "СБЕРБАНК Абхазии",
        "alias": "sberbankabhazii"
      },
      {
        "id": 75,
        "label": "ЦМРБанк",
        "alias": "tsmrbank"
      },
      {
        "id": 76,
        "label": "Беларусбанк",
        "alias": "belarusbank"
      },
      {
        "id": 77,
        "label": "Точка банк",
        "alias": "tochkabank"
      },
      {
        "id": 78,
        "label": "Aurora bank",
        "alias": "aurorabank"
      },
      {
        "id": 79,
        "label": "Кибит-банк",
        "alias": "kibitbank"
      },
      {
        "id": 80,
        "label": "НОРВИК БАНК",
        "alias": "norvikbank"
      },
      {
        "id": 81,
        "label": "Банк Приморье",
        "alias": "bankprimorye"
      },
      {
        "id": 82,
        "label": "СДМ банк",
        "alias": "sdmbank"
      },
      {
        "id": 83,
        "label": "Анор Банк",
        "alias": "anorbank"
      },
      {
        "id": 84,
        "label": "Нико Банк",
        "alias": "nikobank"
      },
      {
        "id": 85,
        "label": "Банк Казани",
        "alias": "bankkazani"
      },
      {
        "id": 86,
        "label": "Капитал банк",
        "alias": "kapitalbank"
      },
      {
        "id": 87,
        "label": "АПРА",
        "alias": "apra"
      },
      {
        "id": 88,
        "label": "БАНК ОРЕНБУРГ",
        "alias": "bankorenburg"
      },
      {
        "id": 89,
        "label": "Мир Привилегий",
        "alias": "mirpriviligii"
      },
      {
        "id": 90,
        "label": "Jusan Bank",
        "alias": "jusanbank"
      },
      {
        "id": 91,
        "label": "Halyk Bank",
        "alias": "halykbank"
      },
      {
        "id": 92,
        "label": "HUMO",
        "alias": "humo"
      },
      {
        "id": 93,
        "label": "НС Банк",
        "alias": "nsbank"
      },
      {
        "id": 94,
        "label": "Дальневосточный банк",
        "alias": "dalnevostochniybank"
      },
      {
        "id": 95,
        "label": "Банк Акцепт",
        "alias": "bankakcept"
      },
      {
        "id": 96,
        "label": "Локо-Банк",
        "alias": "lokobank"
      },
      {
        "id": 97,
        "label": "Таврический Банк",
        "alias": "tavricheskiybank"
      },
      {
        "id": 98,
        "label": "Вайлдберриз банк",
        "alias": "wildberriesbank"
      },
      {
        "id": 99,
        "label": "АТБ",
        "alias": "atb"
      },
      {
        "id": 100,
        "label": "Кошелек",
        "alias": "koshelek"
      },
      {
        "id": 101,
        "label": "Цупис",
        "alias": "tsupis"
      },
      {
        "id": 102,
        "label": "Пойдем",
        "alias": "poidem"
      },
      {
        "id": 103,
        "label": "А-Мобайл кошелек",
        "alias": "amobilekoshelek"
      },
      {
        "id": 104,
        "label": "Mbank",
        "alias": "mbank"
      },
      {
        "id": 105,
        "label": "Айыл Банк",
        "alias": "ayylbank"
      },
      {
        "id": 106,
        "label": "Элдик Банк",
        "alias": "eldikbank"
      },
      {
        "id": 107,
        "label": "Керемет Банк",
        "alias": "keremetbank"
      },
      {
        "id": 108,
        "label": "Банк Левобережный",
        "alias": "banklevoberezhny"
      },
      {
        "id": 109,
        "label": "Банк Хлынов",
        "alias": "bankkhlynov"
      },
      {
        "id": 110,
        "label": "Банк Траст",
        "alias": "banktrast"
      },
      {
        "id": 111,
        "label": "Банк Возрождение",
        "alias": "bankvozrozhdenie"
      },
      {
        "id": 112,
        "label": "Белгородсоцбанк",
        "alias": "belgorodsotsbank"
      },
      {
        "id": 113,
        "label": "СКБ - банк (Синара банк)",
        "alias": "skbbank"
      },
      {
        "id": 114,
        "label": "Банк Кубань кредит",
        "alias": "bankkubankredit"
      },
      {
        "id": 115,
        "label": "АТБ",
        "alias": "atb"
      },
      {
        "id": 116,
        "label": "ВБРР",
        "alias": "vbrr"
      },
      {
        "id": 117,
        "label": "Энерготрансбанк",
        "alias": "energotransbank"
      },
      {
        "id": 118,
        "label": "Авито Кошелек",
        "alias": "avitokoshelek"
      },
      {
        "id": 119,
        "label": "VK Pay - ВК Платёжные решения",
        "alias": "vkpay"
      },
      {
        "id": 120,
        "label": "Амонатбанк",
        "alias": "amonatbank"
      },
      {
        "id": 121,
        "label": "Эсхата",
        "alias": "eshata"
      },
      {
        "id": 122,
        "label": "Бакай Банк",
        "alias": "bakaybank"
      },
      {
        "id": 123,
        "label": "БыстроБанк",
        "alias": "bystrobank"
      },
      {
        "id": 124,
        "label": "Кредит Урал Банк",
        "alias": "kredituralbank"
      },
      {
        "id": 125,
        "label": "Банк Вологжанин",
        "alias": "bankvologzhanin"
      },
      {
        "id": 126,
        "label": "Хакассинский муниципальный банк",
        "alias": "khakassinskymunicipalnybank"
      },
      {
        "id": 127,
        "label": "Газтрансбанк",
        "alias": "gaztransbank"
      },
      {
        "id": 128,
        "label": "Финстар Банк",
        "alias": "finstarbank"
      },
      {
        "id": 129,
        "label": "Элплат",
        "alias": "elplat"
      },
      {
        "id": 130,
        "label": "БАНК ИНТЕЗА",
        "alias": "bankinteza"
      },
      {
        "id": 131,
        "label": "OptimaBank",
        "alias": "optimabank"
      },
      {
        "id": 132,
        "label": "Банк Компаньон",
        "alias": "bankkompanyon"
      },
      {
        "id": 133,
        "label": "Тавхид",
        "alias": "tavkhid"
      },
      {
        "id": 134,
        "label": "Долинск Банк",
        "alias": "dolinskbank"
      },
      {
        "id": 135,
        "label": "Банк Екатеринбург (Контур Банк)",
        "alias": "bankekaterinburg"
      },
      {
        "id": 136,
        "label": "Национальный стандарт",
        "alias": "natsionalniystandart"
      },
      {
        "id": 137,
        "label": 'АО "РЕАЛИСТ БАНК"',
        "alias": "aorealistbank"
      },
      {
        "id": 138,
        "label": "ХУМО",
        "alias": "khumo"
      },
      {
        "id": 139,
        "label": "Vasl",
        "alias": "vasl"
      },
      {
        "id": 140,
        "label": "Namba One",
        "alias": "nambaone"
      },
      {
        "id": 141,
        "label": "KICB",
        "alias": "kicb"
      },
      {
        "id": 142,
        "label": "DemirBank",
        "alias": "demirbank"
      },
      {
        "id": 143,
        "label": "Банк Венец",
        "alias": "bankvenets"
      },
      {
        "id": 144,
        "label": "ПКСБ БАНК",
        "alias": "pksbbank"
      },
    ],
    operators: [
      'МТС',
      'МегаФон',
      'Билайн',
      'Tele2',
      'Тинькофф Мобайл',
      'Юмани',
      'Yota',
      'СберМобайл',
      'Ростелеком',
    ],
    rate: { source: '', value: 0, updated: 0 },
  },
  getters: {
    settings: state => state.settings,
    positions: state => state.positions,
    userBalance: state => state.userBalance,
    banks: state => state.banks,
    paymentSystems: state => state.paymentSystems,
    operators: state => state.operators,
    rate: state => {
      return { ...state.rate, updated: new Date(state.rate.updated).toLocaleString() }
    },
  },
  actions: {
    [LOAD_SETTINGS]: async ({ dispatch }) => {
      await dispatch(HTTP_GET, {
        method: LOAD_SETTINGS,
        namespace: 'settings',
      }, { root: true });
    },
    [UPDATE_SETTING]: async ({ dispatch }, data) => {
      const request = await dispatch(HTTP_PUT, {
        method: UPDATE_SETTING,
        namespace: 'settings',
        no_commit: true,
        body: data,
      }, { root: true });
      if (request && request.data) {
        return request.data;
      } else {
        return { success: false, message: 'Bad request' };
      }
    },
    [LOAD_USER_BALANCE]: async ({ dispatch }) => {
      await dispatch(HTTP_GET, {
        method: LOAD_USER_BALANCE,
        namespace: 'settings',
      }, { root: true });
    },
    [LOAD_EXCHANGE_INFO]: async ({ dispatch }) => {
      await dispatch(HTTP_GET, {
        method: LOAD_EXCHANGE_INFO,
        namespace: 'settings',
      }, { root: true });
    },
  },
  mutations: {
    [LOAD_SETTINGS]: (state, { data }) => {
      Vue.set(state, 'settings', data);
    },
    [LOAD_USER_BALANCE]: (state, { data }) => {
      Vue.set(state.userBalance, 'balance', data.balance);
      Vue.set(state.userBalance, 'balance_dep', data.balance_dep);
      const updated = new Date();
      Vue.set(state.userBalance, 'updated', updated.toLocaleTimeString());
    },
    [LOAD_EXCHANGE_INFO]: (state, { data = {} }) => {
      if (data.rate) {
        Vue.set(state, 'rate', data.rate);
      }
    },
    [RESET_SETTINGS]: (state) => {
      Vue.set(state, 'userBalance', {
        balance: 0,
        balance_dep: 0,
        updated: '',
      });
    },
  },
};
