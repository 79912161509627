<template lang="pug">
  .module__main
    .module-content__container(v-loading="isLoading")
      .settings__container
        .setting__container
          .setting__title Статус работы
          el-switch(v-model="currentSettings.work_status" @change="updateWorkStatus()")
        .setting__container
          .setting__title Новая версия API Saudi
          el-switch(v-model="currentSettings.is_new_saudi_create_deal_version" @change="updateSaudiCreateDealVersion()")
        .setting__container(v-if="currentSettings.handlers_priority")
          .setting__title Приоритет обработчиков
          .settings-handlers__container
            .handler__block(v-for="(handler, index) in currentSettings.handlers_priority" :key="`handler-priority-${handler}`")
              .handler__name {{ handler }}
              .handler__btns
                i.el-icon-arrow-up(v-if="index !== 0" @click="swapHandlers(index, index - 1)")
                i.el-icon-arrow-down(v-if="index !== currentSettings.handlers_priority.length - 1" @click="swapHandlers(index, index + 1)")
            el-button.handler-btn(@click="updateHandlersPriority()" type="success" size="mini") Обновить
        .setting__container(v-if="currentSettings.card_priority_banks")
          .setting__title Приоритетные банки
          .priority-banks__container
            .val(v-for="bank in currentSettings.card_priority_banks")
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { LOAD_SETTINGS, UPDATE_SETTING } from "../../store/const/settings";

export default {
  name: 'Settings',
  data() {
    return {
      currentSettings: {
        work_status: false,
        handlers_priority: [],
        card_priority_banks: [],
        newPriorityBank: '',
        is_new_saudi_create_deal_version: false,
      },
    }
  },
  async mounted() {
    await this.loadSettings();
  },
  watch: {
    settings(val) {
      this.currentSettings = { ...val };
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'error',
    ]),
    ...mapGetters('settings', [
      'settings',
      'banks',
    ]),
    isLoading() {
      return this.loading(LOAD_SETTINGS) === 'loading';
    },
    isError() {
      return this.loading(LOAD_SETTINGS) === 'error';
    },
    errorMessage() {
      return this.error(LOAD_SETTINGS);
    },
    banksToAddToPriority() {
      return this.banks.map((c) => c.alias).filter((c) => !this.currentSettings.card_priority_banks.includes(c));
    },
  },
  methods: {
    ...mapActions('settings', {
      loadSettings: LOAD_SETTINGS,
      updateSetting: UPDATE_SETTING,
    }),
    async requestSettingUpdate(name, setting) {
      const request = await this.updateSetting({ name, setting });
      if (request.success) {
        this.$message.success('Настройки успешно обновлены');
        await this.loadSettings();
        this.currentSettings[name] = setting;
        return true;
      } else {
        this.$message.error(`Ошибка обновления настроек: ${request.message || 'Неизвестная ошибка'}`);
        return false;
      }
    },
    updateWorkStatus() {
      const isUpdateSuccess = this.requestSettingUpdate('work_status', this.currentSettings.work_status);
      if (!isUpdateSuccess) {
        this.currentSettings.work_status = !this.currentSettings.work_status;
      }
    },
    updateSaudiCreateDealVersion() {
      const isUpdateSuccess = this.requestSettingUpdate('is_new_saudi_create_deal_version', this.currentSettings.is_new_saudi_create_deal_version);
      if (!isUpdateSuccess) {
        this.currentSettings.is_new_saudi_create_deal_version = !this.currentSettings.is_new_saudi_create_deal_version;
      }
    },
    updateHandlersPriority() {
      this.requestSettingUpdate('handlers_priority', this.currentSettings.handlers_priority);
    },
    swapHandlers(oldIndex, newIndex) {
      const newHandlersArray = [...this.currentSettings.handlers_priority];
      const newIndexValue = this.currentSettings.handlers_priority[newIndex];
      newHandlersArray[newIndex] = newHandlersArray[oldIndex];
      newHandlersArray[oldIndex] = newIndexValue;
      this.currentSettings.handlers_priority = [...newHandlersArray];
    },
  }
};
</script>
<style scoped>
.settings__container {
    display: flex;
    flex-wrap: wrap;
}

.setting__container {
    width: 240px;
    padding: 10px;
    background-color: white;
    margin: 5px;
    border-radius: 5px;
    box-shadow: 1px 1px 1px #9f9f9f
}

.setting__title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
}

.settings-ads-ids__container {
    overflow-y: scroll;
    max-height: 200px;
}

.settings-ads-ids__id {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.settings-ads-ids__id .val {
    margin-right: 5px;
}

.settings-ads-ids__add {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.settings-ads-ids__add button {
    margin-left: 5px;
}

.handler__block {
    display: flex;
    padding: 4px;
    justify-content: space-between;
    border-bottom: 1px dotted #909399;
}

.handler__btns i {
    cursor: pointer;
}

.handler__btns i:hover {
    color: red;
}

.handler-btn {
    margin-top: 8px;
}
</style>
