import Vue from 'vue'
import { HTTP_GET, HTTP_POST, HTTP_PUT } from '../const';
import {
  ADD_ORDER, CANCEL_ORDER,
  EDIT_ORDER,
  FINISH_ORDER,
  LOAD_DEALS_LIST,
  LOAD_ORDERS_LIST, LOAD_ORDERS_STATS, RENEW_ORDER,
  RESET_ORDERS, SET_COMMISSION, SWITCH_NOTE
} from "../const/orders";

export default {
  namespaced: true,
  state: {
    orders: {
      pagination: {},
      orders: [],
    },
    deals: [],
  },
  getters: {
    orders: state => state.orders,
    deals: state => state.deals,
  },
  actions: {
    [LOAD_ORDERS_LIST]: async ({ dispatch }, { params }) => {
      await dispatch(HTTP_GET, {
        method: LOAD_ORDERS_LIST,
        namespace: 'orders',
        params,
      }, { root: true });
    },
    [LOAD_ORDERS_STATS]: async ({ dispatch }) => {
      const request = await dispatch(HTTP_GET, {
        method: LOAD_ORDERS_STATS,
        namespace: 'orders',
        no_commit: true,
      }, { root: true });
      return request ? request.data : { success: false, message: 'Неудачный запрос' };
    },
    [EDIT_ORDER]: async ({ dispatch }, { id, data }) => {
      id = id.replace(/#/g, '____');
      const request = await dispatch(HTTP_PUT, {
        method: EDIT_ORDER,
        namespace: 'orders',
        body: { data },
        replace: { id },
        no_commit: true,
      }, { root: true });
      return request ? request.data : { success: false, message: 'Неудачный запрос' };
    },
    [LOAD_DEALS_LIST]: async ({ dispatch }, { order_id }) => {
      order_id = order_id.replace(/#/g, '____');
      const request = await dispatch(HTTP_GET, {
        method: LOAD_DEALS_LIST,
        namespace: 'orders',
        replace: { id: order_id },
      }, { root: true });
      return request ? request.data : { success: false, message: 'Неудачный запрос' };
    },
    [FINISH_ORDER]: async ({ dispatch }, { id, source }) => {
      id = id.replace(/#/g, '____');
      const request = await dispatch(HTTP_PUT, {
        method: FINISH_ORDER,
        namespace: 'orders',
        replace: { id },
        body: { source },
        no_commit: true,
      }, { root: true });
      return request ? request.data : { success: false, message: 'Неудачный запрос' };
    },
    [CANCEL_ORDER]: async ({ dispatch }, { id, source }) => {
      id = id.replace(/#/g, '____');
      const request = await dispatch(HTTP_PUT, {
        method: CANCEL_ORDER,
        namespace: 'orders',
        replace: { id },
        body: { source },
        no_commit: true,
      }, { root: true });
      return request ? request.data : { success: false, message: 'Неудачный запрос' };
    },
    [RENEW_ORDER]: async ({ dispatch }, { id, source }) => {
      id = id.replace(/#/g, '____');
      const request = await dispatch(HTTP_PUT, {
        method: RENEW_ORDER,
        namespace: 'orders',
        replace: { id },
        body: { source },
        no_commit: true,
      }, { root: true });
      return request ? request.data : { success: false, message: 'Неудачный запрос' };
    },
    [SET_COMMISSION]: async ({ dispatch }, { id, source, commission }) => {
      id = ('' + id).replace(/#/g, '____');
      const request = await dispatch(HTTP_PUT, {
        method: SET_COMMISSION,
        namespace: 'orders',
        replace: { id },
        body: { source, commission },
        no_commit: true,
      }, { root: true });
      return request ? request.data : { success: false, message: 'Неудачный запрос' };
    },
    [ADD_ORDER]: async ({ dispatch }, body) => {
      const request = await dispatch(HTTP_POST, {
        method: ADD_ORDER,
        namespace: 'orders',
        body,
        no_commit: true,
      }, { root: true });
      return request ? request.data : { success: false, message: 'Неудачный запрос' };
    },
    [SWITCH_NOTE]: async ({ dispatch }, { id, type }) => {
      id = id.replace(/#/g, '____');
      const request = await dispatch(HTTP_PUT, {
        method: SWITCH_NOTE,
        namespace: 'orders',
        replace: { id, type },
        no_commit: true,
      }, { root: true });
      return request ? request.data : { success: false, message: 'Неудачный запрос' };
    },
  },
  mutations: {
    [LOAD_ORDERS_LIST]: (state, { data }) => {
      Vue.set(state.orders, 'orders', data.orders);
      Vue.set(state.orders, 'pagination', data.pagination);
    },
    [LOAD_DEALS_LIST]: (state, { data }) => {
      Vue.set(state, 'deals', data.deals);
    },
    [RESET_ORDERS]: (state) => {
      Vue.set(state, 'orders', { pagination: {}, orders: []});
    }
  },
};
