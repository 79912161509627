export const LOAD_ORDERS_LIST = 'LOAD_ORDERS_LIST';
export const LOAD_ORDERS_STATS = 'LOAD_ORDERS_STATS';
export const EDIT_ORDER = 'EDIT_ORDER';
export const FINISH_ORDER = 'FINISH_ORDER';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const RENEW_ORDER = 'RENEW_ORDER';
export const SET_COMMISSION = 'SET_COMMISSION';
export const RESET_ORDERS = 'RESET_ORDERS';
export const LOAD_DEALS_LIST = 'LOAD_DEALS_LIST';
export const ADD_ORDER = 'ADD_ORDER';
export const SWITCH_NOTE = 'SWITCH_NOTE';
