import {
  ADD_GROUP,
  ADD_MEMBER_TO_GROUP,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  CREATE_USER,
  EDIT_BALANCE,
  EDIT_USER,
  LOAD_BALANCE_CHANGES,
  LOAD_BALANCE_USERS,
  LOAD_CSV_STATS, LOAD_GROUPS,
  LOAD_STATS,
  LOAD_USERS_LIST, REMOVE_GROUP, REMOVE_MEMBER_FROM_GROUP,
} from './user';
import {
  LOAD_EXCHANGE_INFO,
  LOAD_SETTINGS, LOAD_USER_BALANCE,
  UPDATE_SETTING
} from "./settings";
import {
  ADD_ORDER, CANCEL_ORDER,
  EDIT_ORDER,
  FINISH_ORDER,
  LOAD_DEALS_LIST,
  LOAD_ORDERS_LIST, LOAD_ORDERS_STATS, RENEW_ORDER, SET_COMMISSION, SWITCH_NOTE
} from "./orders";
import { ADD_BIN, DELETE_BIN, LOAD_BINS_LIST } from "./bins";


const path = {
  [AUTH_LOGIN]: 'api/users/login',
  [AUTH_LOGOUT]: 'api/users/logout',
  [LOAD_USERS_LIST]: 'api/users',
  [CREATE_USER]: 'api/users',
  [EDIT_USER]: 'api/users/:id:',
  [EDIT_BALANCE]: 'api/users/:id:/balance',
  [LOAD_USER_BALANCE]: 'api/user/balance',
  [LOAD_ORDERS_LIST]: 'api/orders',
  [LOAD_ORDERS_STATS]: 'api/orders/stats',
  [ADD_ORDER]: 'api/orders',
  [EDIT_ORDER]: 'api/orders/:id:',
  [FINISH_ORDER]: 'api/orders/:id:/finish',
  [CANCEL_ORDER]: 'api/orders/:id:/cancel',
  [RENEW_ORDER]: 'api/orders/:id:/renew',
  [SET_COMMISSION]: 'api/orders/:id:/commission',
  [LOAD_BINS_LIST]: 'api/bins',
  [ADD_BIN]: 'api/bins',
  [DELETE_BIN]: 'api/bins',
  [LOAD_SETTINGS]: 'api/settings/global',
  [UPDATE_SETTING]: 'api/settings/global',
  [LOAD_DEALS_LIST]: 'api/orders/:id:/deals',
  [LOAD_STATS]: 'api/user/stats',
  [LOAD_CSV_STATS]: 'api/user/stats/csv',
  [LOAD_BALANCE_USERS]: 'api/users/balance/users',
  [LOAD_BALANCE_CHANGES]: 'api/user/balance/changes',
  [LOAD_GROUPS]: 'api/user/groups',
  [ADD_GROUP]: 'api/user/groups',
  [REMOVE_GROUP]: 'api/user/groups',
  [ADD_MEMBER_TO_GROUP]: 'api/user/groups/:group:',
  [REMOVE_MEMBER_FROM_GROUP]: 'api/user/groups/:group:',
  [SWITCH_NOTE]: 'api/orders/:id:/notes/:type:',
  [LOAD_EXCHANGE_INFO]: 'api/exchange/info',
};
export default path;
