<template lang="pug">
  .main(style="height: 100vh")
    el-row(style="height: 100vh")
      el-col.menu__container(:span="isMenuCollapsed ? 1 : 4")
        .menu__block
          el-menu.el-menu-vertical-demo.menu__list(default-active="0" :collapse="isMenuCollapsed" :collapse-transition="false")
            .balance__container(v-if="role === 'client'")
              i.el-icon-money.balance__icon
              .balance-amounts__container
                .balance-amount__container
                  span.balance__title Общий:
                  span {{ `${userBalance.balance} BTC` }}
                .balance-amount__container
                  span.balance__title Активный:
                  span {{ `${userBalance.balance_dep} BTC` }}
              .balance-updated__container
                span.balance__title Обновлено:
                span {{ userBalance.updated }}
            .menu-width-handler__container(@click="handleFullMenuStatus" :class="{ collapsed: isMenuCollapsed }")
              i.el-icon-caret-right(v-if="isMenuCollapsed")
              i.el-icon-caret-left(v-else)
            router-link(
              v-for="(menu, key) in availableMenus"
              :to="{name: menu.name}"
              :key="`menu-${menu.name}`"
              tag="a")
              el-menu-item(:index="`${key}`")
                i(:class="menu.icon")
                span(slot="title") {{ menu.title }}
            el-menu-item(:index="`${logoutIndex}`" @click="logout")
              i.el-icon-switch-button
              span Выйти
      el-col(:span="isMenuCollapsed ? 23 : 20" style="height: 100vh")
        .exchange-info__container(v-if="isAdmin")
          .exchange-info-balance__container(v-if="rate.value")
            .exchange-info-balance__val
              span {{ `Курс биржи (${rate.source}): ` }}
              span {{ rate.value }} руб.
            .exchange-info-balance__val
              span Обновлен:
              span {{ rate.updated }}
        .view-title__container
          .view-title__name(v-if="meta.title") {{ meta.title }}
          .current-user__container
            .current-user__name {{ username }}
            i.el-icon-refresh.current-user-switch__btn(@click="openLogin()")
            i.el-icon-warning.current-user-switch__btn(@click="clearStorage()")
        router-view
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { CHANGE_RELOAD_STATUS } from '../../store/const/index';
  import { AUTH_LOGOUT } from '../../store/const/user';
  import { LOAD_EXCHANGE_INFO, LOAD_USER_BALANCE } from "../../store/const/settings";

  export default {
    name: "Index",
    data() {
      return {
        menuList: [
          {
            name: 'OrdersList',
            title: 'Заявки на обнал',
            icon: 'el-icon-s-grid',
            access: ['client', 'admin', 'superadmin', 'trader']
          },
          {
            name: 'BinsList',
            title: 'Список BIN',
            icon: 'el-icon-bank-card',
            access: ['superadmin']
          },
          {
            name: 'Stats',
            title: 'Статистика',
            icon: 'el-icon-s-data',
            access: ['admin', 'superadmin', 'client']
          },
          {
            name: 'Balances',
            title: 'Баланс',
            icon: 'el-icon-money',
            access: ['superadmin', 'client', 'partner']
          },
          {
            name: 'UsersList',
            title: 'Пользователи',
            icon: 'el-icon-user',
            access: ['superadmin']
          },
          {
            name: 'Groups',
            title: 'Группы',
            icon: 'el-icon-receiving',
            access: ['superadmin']
          },
          {
            name: 'Settings',
            title: 'Настройки',
            icon: 'el-icon-setting',
            access: ['superadmin']
          },
         ],
        isUserSwitchOpened: false,
        isMenuCollapsed: false,
      }
    },
    async created() {
      await this.loadUserBalance();
      if (this.isAdmin) {
        await this.loadExchangeInfo();
      }
      this.startInterval();
    },
    beforeDestroy() {
      clearInterval(this.intervalHandle);
    },
    computed: {
      ...mapGetters('user', {
        role: 'getRole',
        isAdmin: 'isAdmin',
        sessions: 'sessions',
        username: 'username',
      }),
      ...mapGetters('settings', {
        userBalance: 'userBalance',
        rate: 'rate',
      }),
      availableMenus() {
        return this.menuList.filter((menu) => menu.access.includes(this.role));
      },
      logoutIndex() {
        return this.availableMenus.length;
      },
      meta() {
        return this.$route.meta;
      }
    },
    methods: {
      ...mapActions({
        changeReloadStatus: CHANGE_RELOAD_STATUS,
      }),
      ...mapActions('user', {
        logoutRequest: AUTH_LOGOUT,
      }),
      ...mapActions('settings', {
        loadUserBalance: LOAD_USER_BALANCE,
        loadExchangeInfo: LOAD_EXCHANGE_INFO,
      }),
      async logout() {
        await this.logoutRequest();
      },
      async openLogin() {
        await this.$router.push({ name: 'Login' });
      },
      async clearStorage() {
        this.$confirm('ВЫ ТОЧНО УВЕРЕНЫ???').then(() => {
          localStorage.clear();
          this.$router.go(0);
        }).catch(() => {
          this.$message('Славненько')
        })
      },
      startInterval(){
        this.intervalHandle = setInterval(() => {
          this.loadUserBalance();
          if (this.isAdmin) {
            this.loadExchangeInfo();
          }
        }, 10000);
      },
      handleFullMenuStatus() {
        this.isMenuCollapsed = !this.isMenuCollapsed;
      },
    }
  }
</script>

<style lang="scss">
  .view-title__container {
    display: flex;
    font-size: 20px;
    height: 29px;
    padding: 10px;
    background-color: #efefef;
    text-shadow: 1px 1px 1px #cce0f1;
    color: #05447a;
    font-weight: bold;
    text-align: center;
  }
  .view-title__name {
    flex: 1
  }
  .current-user__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .current-user-switch__btn {
    margin-left: 10px;
    cursor: pointer;
  }
	.main {
		text-align: left;
	}

  .menu__list {
    height: 100%;
  }

  .mode-select__container {
    padding: 10px 0;
    margin: auto;
    text-align: center;
    border-bottom: 1px solid #e6e6e6;
  }

  .mode-select__container .title {
    font-weight: 500;
    margin-bottom: 5px;
  }

  .module__main {
    overflow-y: hidden;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
  }

  .module__title {
    font-size: 20px;
    height: 29px;
    padding: 10px;
    background-color: #efefef;
    text-shadow: 1px 1px 1px #cce0f1;
    color: #05447a;
    font-weight: bold;
    text-align: center;
  }

  .module-content__container {
    display: flex;
    flex-direction: column;
    padding: 5px;
    background-color: #e3e0e0;
    border-left: 1px solid #d1cece;
    border-top: 1px solid #d1cece;
    overflow-y: hidden;
    height: 100%;
  }

  .menu__container {
    display: flex;
    height: 100vh;
    flex-direction: column;
    background-color: #efefef;
    padding: 4px;
  }

  .menu__block {
    height: 100%;
  }

  .menu-width-handler__container {
    cursor: pointer;
    width: 100%;
    text-align: right;
  }

  .menu-width-handler__container.collapsed {
    text-align: center;
  }

  .menu-width-handler__container:hover {
    background-color: #f7f7f7;
  }

  .table__container {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
  }

  .table-list__container {
    overflow-y: scroll;
    border-radius: 5px;
  }

  .table-pagination__container {
    margin-bottom: 5px;
    text-align: center;
  }

  .table-list__container .val {
    text-overflow: ellipsis;
    word-break: break-word;
  }

  .dialog-add__input {
    width: 100%;
    margin: 5px auto;
  }

  .color-success {
    color: #67C23A;
  }

  .background-success {
    background-color: #67C23A;
  }

  .color-warning {
    color: #E6A23C;
  }

  .background-warning {
    background-color: #E6A23C;
  }

  .color-danger {
    color: #F56C6C;
  }

  .background-danger {
    background-color: #F56C6C;
  }

  .color-info {
    color: #909399;
  }

  .background-info {
    background-color: #909399;
  }

  .color-primary {
    color: #909399;
  }

  .background-primary {
    background-color: #409EFF;
  }

  tr.row-success {
    background-color: #c4f8ae !important;
  }

  tr.row-danger {
    background-color: #fcbbbb !important;
  }

  .balance__container {
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: #efefef;
    font-size: 12px
  }

  .balance-amounts__container {
    margin: auto 10px;
  }

  .balance-amount__container {
    display: flex;
  }

  .balance__icon {
    color: green;
    font-size: 16px;
  }

  .balance__title {
    margin-right: 5px;
    width: 60px;
  }

  .menu__block a {
    text-decoration: none;
  }

  .exchange-info__container {
    background-color: #efefef;
    padding: 4px;
  }

  .exchange-info-balance__container {
    font-family: monospace;
    font-size: 15px;
    display: flex;
  }

  .exchange-info-balance__val {
    margin: auto 4px;
  }
</style>

